import * as React from 'react';
import styled from '@emotion/styled';
import TextButton from '~/components/buttons/TextButton';
import { navigate } from 'gatsby';
import { useTheme, css } from '@emotion/react';
import { useApp } from '~/provider/AppProvider';
import {
  FaCcAmex,
  FaCcMastercard,
  FaCcPaypal,
  FaCcVisa,
  FaInstagram,
  FaInstagramSquare,
  FaCcStripe,
} from 'react-icons/fa';
import { MainContainer } from '../containers';
import TransitionLink from 'gatsby-plugin-transition-link';
import Link from '../Link';
import Icon from '../Icon';
import TwintIcon from './TwintIcon';
import theme from '../../styles/theme';
import { useDeliveryInfo, DAYS_TEXT } from '~/provider/DeliveryInfoProvider';
import useIntersection from '../../hooks/useIntersection';

const container = (theme) =>
  css({
    marginTop: theme.spacing(20),
    alignSelf: 'center',
    justifySelf: 'center',
    borderTopStyle: 'solid',
    borderTopWidth: 1,
    borderTopColor: theme.colors.sand,
    paddingTop: theme.spacing(4),
  });

const rowItemContainer = (theme) =>
  css({
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing(-1),
    marginTop: theme.spacing(-1),
  });

const paymentIcon = (theme) =>
  css({
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(1),
  });

const linkItemContainer = (theme) =>
  css({
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing(-3),
    marginTop: theme.spacing(-1),
  });

const linkItem = (theme) =>
  css({
    marginLeft: theme.spacing(3),
    marginTop: theme.spacing(1),
  });

const sectionTitle = (theme) =>
  css({
    color: theme.colors.sand,
    marginBottom: theme.spacing(1),
    textTransform: 'uppercase',
    fontWeight: 'bold',
    fontSize: '1rem',
  });

const sectionContainer = (theme) =>
  css({
    flex: 1,
    flexBasis: 150,
    marginLeft: theme.spacing(8),
    marginTop: theme.spacing(5),
    display: 'flex',
    flexDirection: 'column',
  });

const contactLinkItem = (theme) =>
  css({
    marginTop: theme.spacing(1),
    color: 'inherit',
    textDecoration: 'none',
    ...theme.fonts.futura,
    color: theme.colors.sand,
  });

const PAYMENT_PROVIDERS = [
  TwintIcon,
  FaCcMastercard,
  FaCcVisa,
  FaCcPaypal,
  FaCcAmex,
  FaCcStripe,
];

const SOCIAL_MEDIA = [
  {
    icon: FaInstagram,
    link: 'https://www.instagram.com/nahschub/',
  },
];

export const LEGAL_ITEMS = [
  {
    path: '/agb',
    label: 'AGB',
  },
  {
    path: '/datenschutz',
    label: 'Datenschutz',
  },
  {
    path: '/impressum',
    label: 'Impressum',
  },
];

const LINK_ITEMS = [
  {
    path: '/',
    label: 'Home',
  },
  {
    path: '/menuecharte',
    label: 'Menücharte',
  },
  {
    path: '/lieferinformationen',
    label: 'Lieferinfos',
  },
  {
    path: '/ueber',
    label: 'Über eus',
  },
];

const CONTACT_LINKS = [
  { label: 'bar@nahschub.ch', link: 'mailto:bar@nahschub.ch' },
  { label: '076 421 95 99', link: 'tel:0764219599' },
];

function Footer({ className }) {
  const theme = useTheme();
  const { deliveryTimes } = useDeliveryInfo();
  const { setFooterVisible } = useApp();
  const footerRef = React.useRef();

  const [entry] = useIntersection(footerRef);
  const isIntersecting = React.useMemo(() => entry?.isIntersecting, [entry]);

  React.useEffect(() => {
    setFooterVisible(isIntersecting);
  }, [isIntersecting]);

  function renderPaymentSection() {
    return (
      <div css={sectionContainer}>
        <span css={sectionTitle}>Bezahlig</span>
        <div css={rowItemContainer}>
          {PAYMENT_PROVIDERS.map((Icon, idx) => (
            <div key={`payment-${idx.toString()}`} css={paymentIcon}>
              <Icon size={40} fill={theme.colors.sand} />
            </div>
          ))}
        </div>
      </div>
    );
  }

  function renderLinksSection() {
    return (
      <div css={sectionContainer}>
        <span css={sectionTitle}>Links</span>
        <div
          css={{
            display: 'flex',
            flexDirection: 'column',
            marginTop: theme.spacing(-1),
          }}
        >
          {LINK_ITEMS.map((item, idx) => (
            <Link
              key={`link-${item.path}`}
              css={contactLinkItem}
              to={item.path}
            >
              {item.label}
            </Link>
          ))}
        </div>
      </div>
    );
  }

  function renderDelievryTimesSection() {
    const deliveryWindows = Object.keys(DAYS_TEXT).reduce((acc, day) => {
      const dayDeliveryInfo = deliveryTimes?.[day];
      if (!dayDeliveryInfo) {
        return acc;
      }

      const rangeText = dayDeliveryInfo
        .map((di) => `${di.start} – ${di.end}`)
        .join(', ');

      const prevRange = acc[acc.length - 1]?.range;

      if (prevRange === rangeText) {
        acc[acc.length - 1].end = DAYS_TEXT[day];
      } else {
        acc.push({ start: DAYS_TEXT[day], range: rangeText });
      }
      return acc;
    }, []);

    if (deliveryWindows.length > 0) {
      return (
        <div css={sectionContainer}>
          <span css={sectionTitle}>Lieferig</span>
          <div
            css={{
              display: 'flex',
              flexDirection: 'column',
              marginTop: theme.spacing(-1),
            }}
          >
            <div css={[contactLinkItem, { whiteSpace: 'nowrap' }]}>
              Züri Kreis 1 bis 10
            </div>
            {deliveryWindows.map((dw) => (
              <div
                key={dw.start}
                css={[contactLinkItem, { whiteSpace: 'nowrap' }]}
              >
                {`${dw.start}${dw.end ? ` – ${dw.end}` : ''}: ${dw.range}`}
              </div>
            ))}
            <div css={[contactLinkItem, { color: 'inherit' }]}>
              oder nach Absprach
            </div>
          </div>
        </div>
      );
    }
    return null;
  }

  function renderSocialMediaSection() {
    return (
      <div css={sectionContainer}>
        <span css={sectionTitle}>Folg eus</span>
        <div css={rowItemContainer}>
          {SOCIAL_MEDIA.map(({ icon: Icon, link }, idx) => (
            <a
              href={link}
              key={`social-media-${idx.toString()}`}
              css={paymentIcon}
              target="_blank"
            >
              <Icon size={40} fill={theme.colors.sand} />
            </a>
          ))}
        </div>
      </div>
    );
  }

  function renderContactSection() {
    return (
      <div css={sectionContainer}>
        <span css={sectionTitle}>Kontakt</span>
        <div
          css={{
            display: 'flex',
            flexDirection: 'column',
            marginTop: theme.spacing(-1),
          }}
        >
          {CONTACT_LINKS.map((item, idx) => (
            <a
              key={`contact-${item.link}`}
              css={contactLinkItem}
              href={item.link}
            >
              {item.label}
            </a>
          ))}
        </div>
      </div>
    );
  }

  return (
    <MainContainer css={container} fullWidth>
      <div
        ref={footerRef}
        css={{
          display: 'flex',
          alignItems: 'start',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
          marginLeft: theme.spacing(-8),
          marginTop: theme.spacing(-5),
        }}
      >
        {renderContactSection()}
        {renderSocialMediaSection()}
        {renderLinksSection()}
        {renderDelievryTimesSection()}
        {renderPaymentSection()}
      </div>

      <div
        css={{
          marginTop: theme.spacing(5),
          display: 'flex',
          justifyContent: 'space-between',
          flexWrap: 'wrap-reverse',
          ...theme.fonts.futura,
        }}
      >
        <span
          css={{ paddingRight: theme.spacing(8) }}
        >{`© ${new Date().getFullYear()} nahschub KLG`}</span>
        <div css={linkItemContainer}>
          {LEGAL_ITEMS.map((item) => (
            <Link key={item.path} to={item.path} css={linkItem}>
              {item.label}
            </Link>
          ))}
        </div>
      </div>
    </MainContainer>
  );
}

export default Footer;
